import React from 'react'
import { Link } from 'gatsby'

import styles from './footer.module.css'

const footer = () => (
  <div className={styles.footer}>
    <div>
      © {new Date().getFullYear()} Algedonic Loop GmbH
      <div>
        <Link to="/legal-notice">
          Legal Notice
        </Link>
        {` `}—{` `} 
        <Link to="/de/privacy-policy">
          Privacy Policy
        </Link>
      </div>
    </div>
  </div>
)

export default footer
