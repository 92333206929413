import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header/header'
import Footer from './footer/footer'

import './layout.css'

type props = {
  children: any
}

const Layout = ({ children }: props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="container">
      <header className="header">
        <Header siteTitle={data.site.siteMetadata.title} />
      </header>
      <main>{children}</main>
      <footer>
        <Footer />
      </footer>
    </div>
  )
}

export default Layout
