import React from 'react'
import { Link } from 'gatsby'
import styles from './header.module.css'

type props = {
  siteTitle: string
}
console.log(styles)
const Header = ({ siteTitle }: props) => (
  <Link to="/" className={styles.homeLink}><h1 className={styles.siteTitle}>{siteTitle}</h1></Link>
)

export default Header
